// App.tsx
import React, { useState, useEffect } from 'react';

const App = () => {
    const [bankName, setBankName] = useState('default bank name');
    useEffect(() => {
        fetchBankName().then((bankName) => {
            setBankName(bankName)
        })
    }, []);
    
    const fetchBankName = async () => {
        const response = await fetch('/api/bank', {
            headers: {
                'tenant': 'sparebanken-sunnarvik' // Just for testing, ingress will replace this
            }
        });
        const data = await response.json();
        return data;
    };

    return (
        <div>
            <h1>Nettbank {bankName}</h1>
            <p>Velkommen til nettbanken</p>
        </div>
    );
};

export default App;
